<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                              <b-overlay :show="isLoading">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                      <div>
                                        <b-table-simple striped bordered small>
                                          <b-tr>
                                            <b-th>{{ $t('dae_grant_allocation_distribution.purchase_serial_no') }}</b-th>
                                            <b-td>{{ $n(item.purchase_serial_no, { useGrouping: false }) }}</b-td>
                                            <b-th>{{ $t('dae_grant_allocation_distribution.purchase_order_date') }}</b-th>
                                            <b-td>{{ item.purchase_order_date | dateFormat }}</b-td>
                                            <b-th>{{ $t('globalTrans.fiscal_year') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.fiscal_year : item.fiscal_year_bn }}</b-td>
                                          </b-tr>
                                          <b-tr>
                                            <b-th>{{ $t('dae_config.season_name') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.season_name : item.season_name_bn }}</b-td>
                                            <b-th>{{ $t('bsri_config.subsidy_type') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.subsidy_type_name : item.subsidy_type_name_bn }}</b-td>
                                            <b-th>{{ $t('bsri_config.subsidy_name') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.subsidy_name : item.subsidy_name_bn }}</b-td>
                                          </b-tr>
                                          <b-tr>
                                            <b-th>{{ $t('dae_config.project_name') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.project_name : item.project_name_bn }}</b-td>
                                            <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.district_name : item.district_name_bn }}</b-td>
                                            <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.upazilla_name : item.upazilla_name_bn }}</b-td>
                                          </b-tr>
                                          <b-tr>
                                            <b-th>{{ $t('dae_config.s_name') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.supplier_name : item.supplier_name_bn }}</b-td>
                                            <b-th>{{ $t('dae_grant_allocation_distribution.supplier_mobile') }}</b-th>
                                            <b-td>{{ item.supplier_mobile }}</b-td>
                                            <b-th>{{ $t('dae_grant_allocation_distribution.supplier_address') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.supplier_address : item.supplier_address_bn  }}</b-td>
                                          </b-tr>
                                          <b-tr>
                                            <b-th>{{ $t('dae_grant_allocation_distribution.payment_mode') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.payment_mode_name : item.payment_mode_name_bn }}</b-td>
                                            <b-th>{{ $t('dae_grant_allocation_distribution.payment_status') }}</b-th>
                                            <b-td>{{ currentLocale === 'en' ? item.payment_status_name : item.payment_status_name_bn }}</b-td>
                                            <b-th>{{ $t('dae_grant_allocation_distribution.supplier_invoice_date') }}</b-th>
                                            <b-td>{{ item.supplier_invoice_date | dateFormat }}</b-td>
                                          </b-tr>
                                        </b-table-simple>
                                      </div>
                                      <div style="font-size:15px; background-color: #005B5B;">
                                        <h5 class="text-white text-center">
                                          {{ $t('dae_grant_allocation_distribution.stock_register') }} {{ $t('globalTrans.details') }} {{ $t('globalTrans.list') }}
                                        </h5>
                                      </div>
                                      <b-table-simple striped bordered small class="mt-2">
                                        <b-thead>
                                          <tr>
                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th>{{ $t('dae_config.crop_name') }}</b-th>
                                            <b-th>{{ $t('dae_config.agriculture_material_type') }}</b-th>
                                            <b-th>{{ $t('dae_config.agriculture_material_name') }}</b-th>
                                            <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.purchase_quantity') }}</b-th>
                                            <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.receive_quantity') }}</b-th>
                                            <b-th class="text-right">{{ $t('dae_grant_allocation_distribution.available_stock') }}</b-th>
                                          </tr>
                                        </b-thead>
                                        <b-tbody>
                                          <template v-if="formData.stock_register_histories.length">
                                            <b-tr v-for="(item, index) in formData.stock_register_histories" :key="index">
                                              <b-td  class="text-center">{{ index+1 }}</b-td>
                                              <b-td class="text-center">{{ currentLocale === 'en' ? item.crop_name : item.crop_name_bn }}</b-td>
                                              <b-td class="text-center">{{ currentLocale === 'en' ? item.material_type_name : item.material_type_name_bn }}</b-td>
                                              <b-td class="text-center">{{ currentLocale === 'en' ? item.material_name : item.material_name_bn }}</b-td>
                                              <b-td class="text-right">
                                                <ValidationProvider name="Purchase Quantity" vid="purchase_quantity">
                                                  <div
                                                    slot-scope="{ valid, errors }"
                                                    label-for="purchase_quantity"
                                                  >
                                                    <b-form-input
                                                        id="purchase_quantity"
                                                        disabled
                                                        v-model="item.purchase_quantity"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :value="item.purchase_quantity"
                                                    >
                                                    </b-form-input>
                                                  </div>
                                                </ValidationProvider>
                                              </b-td>
                                              <b-td class="text-right">
                                                <ValidationProvider name="Receive Quantity" :vid="`receive_quantity${index}`" rules="required|max_value:@purchase_quantity">
                                                  <div
                                                    slot-scope="{ valid, errors }"
                                                    :label-for="`receive_quantity${index}`"
                                                  >
                                                    <b-form-input
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        id="receive_quantity"
                                                        v-model="item.receive_quantity"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        :placeholder="$t('dae_grant_allocation_distribution.receive_quantity')"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </div>
                                                </ValidationProvider>
                                              </b-td>
                                              <b-td class="text-right">{{ $n(item.avaialble_stock) }}</b-td>
                                            </b-tr>
                                          </template>
                                          <template v-else>
                                            <b-tr>
                                              <b-td colspan="7" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                          </template>
                                        </b-tbody>
                                      </b-table-simple>
                                      <b-row>
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                          <ValidationProvider name="Receive Date" vid="receive_date" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="receive_date"
                                                slot-scope="{ valid, errors }"
                                            >
                                              <template v-slot:label>
                                                {{ $t('dae_grant_allocation_distribution.receive_date') }}
                                              </template>
                                              <b-form-input
                                                  class="fromDate"
                                                  id="receive_date"
                                                  v-model="formData.receive_date"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  :placeholder="$t('dae_grant_allocation_distribution.receive_date')"
                                              >
                                              </b-form-input>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                          <ValidationProvider name="Comments" vid="comments" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="comments"
                                                slot-scope="{ valid, errors }"
                                            >
                                              <template v-slot:label>
                                                {{ $t('dae_grant_allocation_distribution.comment_en') }}
                                              </template>
                                              <b-form-textarea
                                                  id="comments"
                                                  v-model="formData.comments"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              </b-form-textarea>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                          <ValidationProvider name="Comments Bn" vid="comments_bn" rules="">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="5"
                                                label-for="comments_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                              <template v-slot:label>
                                                {{ $t('dae_grant_allocation_distribution.comment_bn') }}
                                              </template>
                                              <b-form-textarea
                                                  id="comments_bn"
                                                  v-model="formData.comments_bn"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                              >
                                              </b-form-textarea>
                                              <div class="invalid-feedback">
                                                {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                      </b-row>
                                      <div class="row">
                                        <div class="col-sm-3"></div>
                                          <div class="col text-right">
                                            <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                                              &nbsp;
                                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                          </div>
                                      </div>
                                    </b-form>
                                </ValidationObserver>
                              </b-overlay>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { stockRegisterStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
      ValidationObserver,
      ValidationProvider
    },
    created () {
      if (this.id) {
        this.getFormData()
      }
    },
    data () {
      return {
        item: {},
        formData: {
          purchase_register_id: '',
          purchase_quantity: '',
          receive_quantity: '',
          receive_date: '',
          comments: '',
          comments_bn: '',
          stock_register_histories: []
        },
        cropList: [],
        grandTotal: 0,
        isLoading: false,
        errorAll: {},
        incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl
      }
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    computed: {
      saveBtnName: function () {
        return this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
      },
      allocationList: function () {
        const allocationList = this.$store.state.incentiveGrant.commonObj.allocationList.filter(item => item.status === 1)
        return allocationList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: this.$n(parseInt(item.text_bn), { useGrouping: false }) }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
      },
      cropNameList: function () {
        return this.$store.state.incentiveGrant.commonObj.cropList.filter(item => item.status === 1)
      },
      agMaterialTypeList: function () {
        return this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.filter(item => item.status === 1)
      },
      agMaterialList: function () {
        return this.$store.state.incentiveGrant.commonObj.agMaterialList.filter(item => item.status === 1)
      },
      supplierList: function () {
        return this.$store.state.incentiveGrant.commonObj.supplierList.filter(item => item.status === 1)
      },
      currentLocale: function () {
        return this.$i18n.locale
      },
      paymentModeList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'Cash' : 'নগদ', value: 1, text_en: 'Cash', text_bn: 'নগদ' },
          { text: this.currentLocale === 'en' ? 'Credit' : 'ক্রেডিট', value: 2, text_en: 'Credit', text_bn: 'ক্রেডিট' }
        ]
      },
      paymentStatusList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'Paid' : 'পরিশোধ', value: 1, text_en: 'Paid', text_bn: 'পরিশোধ' },
          { text: this.currentLocale === 'en' ? 'Unpaid' : 'অবৈতনিক', value: 2, text_en: 'Unpaid', text_bn: 'অবৈতনিক' }
        ]
      }
    },
    methods: {
        getFormData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          this.item = JSON.parse(JSON.stringify(tmpData))
          const allocationObj = this.allocationList.find(allocationItem => allocationItem.value === this.item.allocation_id)
          const supplier = this.supplierList.find(item => item.value === this.item.supplier_id)
          const paymentMode = this.paymentModeList.find(item => item.value === this.item.payment_mode)
          const paymentStatus = this.paymentStatusList.find(item => item.value === this.item.payment_status)
          this.item.allocation_id_custom = parseInt(allocationObj?.text)
          this.item.supplier_name = supplier?.text_en
          this.item.supplier_name_bn = supplier?.text_bn
          this.item.payment_mode_name = paymentMode?.text_en
          this.item.payment_mode_name_bn = paymentMode?.text_bn
          this.item.payment_status_name = paymentStatus?.text_en
          this.item.payment_status_name_bn = paymentStatus?.text_bn
          this.formData.purchase_register_id = this.item.id
          this.formData.stock_register_histories = this.item.purchase_register_details.map(item => {
            const crop = this.cropNameList.find(cropObj => cropObj.value === item.crop_id)
            const agMaterialType = this.agMaterialTypeList.find(agMaterialTypeObj => agMaterialTypeObj.value === item.material_type_id)
            const agMaterial = this.agMaterialList.find(agMaterialObj => agMaterialObj.value === item.material_id)
            const stockRegisterDetail = this.item.stock_register_details.find(stockRegisterDetailObj => {
              if (stockRegisterDetailObj.crop_id === item.crop_id && stockRegisterDetailObj.material_type_id === item.material_type_id && stockRegisterDetailObj.material_id === item.material_id) {
                return stockRegisterDetailObj
              }
            })
            const customItem = {}
            customItem.purchase_register_detail_id = item.id
            customItem.crop_name = crop?.text_en
            customItem.crop_name_bn = crop?.text_bn
            customItem.material_type_name = agMaterialType?.text_en
            customItem.material_type_name_bn = agMaterialType?.text_bn
            customItem.material_name = agMaterial?.text_en
            customItem.material_name_bn = agMaterial?.text_bn
            customItem.purchase_quantity = item.quantity
            customItem.receive_quantity = ''
            customItem.avaialble_stock = typeof stockRegisterDetail !== 'undefined' ? stockRegisterDetail.stock_quantity : 0

            return Object.assign({}, customItem)
          })
          this.getGrandTotal()
        },
        async saveData () {
          this.isLoading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, stockRegisterStore, this.formData)

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

              this.$bvModal.hide('modal-4')
              this.errorAll = ''
            } else {
              this.errorAll = result.errors
              this.$refs.form.setErrors(result.errors)
            }
          this.isLoading = false
        },
      getGrandTotal () {
        let grandTotal = 0
        this.item.purchase_register_details.filter(item => {
          grandTotal += item.total_price
        })
        this.grandTotal = grandTotal
      }
    }
}
</script>
