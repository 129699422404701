
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('dae_grant_allocation_distribution.stock_register') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      :label="$t('globalTrans.fiscal_year')"
                      label-for="fiscal_year_id">
                      <b-form-select
                          plain
                          v-model="search.fiscal_year_id"
                          :options="fiscalList"
                          id="fiscal_year_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      :label="$t('dae_config.season_name')"
                      label-for="season_id">
                      <b-form-select
                          plain
                          v-model="search.season_id"
                          :options="seasonSetupList"
                          id="season_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                    class="row"
                    label-cols-sm="5"
                    :label="$t('bsri_config.subsidy_type')"
                    label-for="subsidy_type_id"
                  >
                      <b-form-select
                        plain
                        v-model="search.subsidy_type_id"
                        :options="subsidyTypeList"
                        id="subsidy_type_id"
                      >
                        <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                    class="row"
                    label-cols-sm="5"
                    :label="$t('bsri_config.subsidy_name')"
                    label-for="subsidy_id"
                  >
                      <b-form-select
                        plain
                        v-model="search.subsidy_id"
                        :options="subsidyList"
                        id="subsidy_id"
                      >
                        <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                    class="row"
                    label-cols-sm="5"
                    :label="$t('bsri_config.project_name')"
                    label-for="project_id"
                  >
                      <b-form-select
                        plain
                        v-model="search.project_id"
                        :options="projectList"
                        id="project_id"
                      >
                        <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                        class="row"
                        label-cols-sm="5"
                        label-for="division_id"
                      >
                        <template v-slot:label>
                          {{ $t('org_pro_division.division')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.division_id"
                          :options="divisionList"
                          id="division_id"
                        >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-group
                        class="row"
                        label-cols-sm="5"
                        label-for="district_id"
                      >
                        <template v-slot:label>
                          {{ $t('org_pro_district.district')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.district_id"
                          :options="districtList"
                          id="district_id"
                        >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="upazila_id"
                    >
                    <template v-slot:label>
                      {{ $t('org_pro_upazilla.upazilla')}}
                    </template>
                      <b-form-select
                        plain
                        v-model="search.upazila_id"
                        :options="upazilaList"
                        id="upazila_id"
                      >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="purchase_order_date_from">
                    <template v-slot:label>
                      {{ $t('dae_grant_allocation_distribution.purchase_order_date') }} {{ $t('globalTrans.from') }}
                    </template>
                    <b-form-input
                        class="fromDate"
                        id="purchase_order_date_from"
                        v-model="search.purchase_order_date_from"
                        :placeholder="$t('dae_grant_allocation_distribution.purchase_order_date') + ' ' + $t('globalTrans.from')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-form-group
                      class="row"
                      label-cols-sm="5"
                      label-for="purchase_order_date_to">
                    <template v-slot:label>
                      {{ $t('dae_grant_allocation_distribution.purchase_order_date') }} {{ $t('globalTrans.to') }}
                    </template>
                    <b-form-input
                        class="fromDate"
                        id="purchase_order_date_to"
                        v-model="search.purchase_order_date_to"
                        :placeholder="$t('dae_grant_allocation_distribution.purchase_order_date') + ' ' + $t('globalTrans.to')"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('purchInvent.stockRegisterList') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table  :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                      <template v-slot:cell(index)="data">
                                          {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(allocation_id_custom)="data">
                                          {{ $n(data.item.allocation_id_custom, { useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(purchase_serial_no)="data">
                                        {{ $n(data.item.purchase_serial_no, { useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(purchase_order_date)="data">
                                        {{ data.item.purchase_order_date | dateFormat }}
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)">{{ $t('dae_grant_allocation_distribution.stock_in') }}</b-button>
                                      </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { stockRegisterPurchaseRegisterList, stockRegisterStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        season_id: 0,
        crop_id: 0,
        subsidy_type_id: 0,
        subsidy_id: 0,
        project_id: 0,
        division_id: 0,
        district_id: 0,
        upazila_id: 0,
        purchase_order_date_from: '',
        purchase_order_date_to: ''
      },
      item: '',
      subsidyList: [],
      districtList: [],
      upazilaList: []
    }
  },
  watch: {
    'search.subsidy_type_id': function (newValue) {
      this.subsidyList = this.getSubsidyList(newValue)
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
      this.upazilaList = []
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  computed: {
    seasonSetupList: function () {
      const seasonData = this.$store.state.incentiveGrant.commonObj.seasonSetupList
      return seasonData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    fiscalList: function () {
      const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyearData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    subsidyTypeList: function () {
      const subsidyTypeList = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.filter(item => item.status === 1)
      return subsidyTypeList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    projectList: function () {
      const projectList = this.$store.state.incentiveGrant.commonObj.projectList.filter(item => item.status === 1)
      return projectList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    divisionList: function () {
      const divisionList = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisionList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    formTitle () {
      return this.$t('dae_grant_allocation_distribution.stock_register')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
        { label: this.$t('dae_grant_allocation_distribution.purchase_serial_no'), class: 'text-center' },
        { label: this.$t('globalTrans.fiscal_year'), class: 'text-center' },
        { label: this.$t('dae_config.season_name'), class: 'text-center' },
        { label: this.$t('bsri_config.subsidy_type'), class: 'text-center' },
        { label: this.$t('bsri_config.subsidy_name'), class: 'text-center' },
        { label: this.$t('bsri_config.project_name'), class: 'text-center' },
        { label: this.$t('org_pro_district.district'), class: 'text-center' },
        { label: this.$t('org_pro_upazilla.upazilla'), class: 'text-center' },
        { label: this.$t('dae_grant_allocation_distribution.purchase_order_date'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'purchase_serial_no' },
          { key: 'fiscal_year_bn' },
          { key: 'season_name_bn' },
          { key: 'subsidy_type_name_bn' },
          { key: 'subsidy_name_bn' },
          { key: 'project_name_bn' },
          { key: 'district_name_bn' },
          { key: 'upazilla_name_bn' },
          { key: 'purchase_order_date' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'purchase_serial_no' },
          { key: 'fiscal_year' },
          { key: 'season_name' },
          { key: 'subsidy_type_name' },
          { key: 'subsidy_name' },
          { key: 'project_name' },
          { key: 'district_name' },
          { key: 'upazilla_name' },
          { key: 'purchase_order_date' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, stockRegisterStatus, item, 'incentive_grant')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, stockRegisterPurchaseRegisterList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const listData = data.map(item => {
        const fiscalYear = this.$store.state.commonObj.fiscalYearList.find(fyr => fyr.value === item.fiscal_year_id)
        const season = this.$store.state.incentiveGrant.commonObj.seasonSetupList.find(season => season.value === item.season_id)
        const subsidyType = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.find(type => type.value === item.subsidy_type_id)
        const subsidy = this.$store.state.incentiveGrant.commonObj.subsidyList.find(sub => sub.value === item.subsidy_id)
        const project = this.$store.state.incentiveGrant.commonObj.projectList.find(prj => prj.value === item.project_id)
        const district = this.$store.state.commonObj.districtList.find(dst => dst.value === item.district_id)
        const upazilla = this.$store.state.commonObj.upazilaList.find(upz => upz.value === item.upazilla_id)

        const fiscalYearObj = { fiscal_year: fiscalYear !== undefined ? fiscalYear.text_en : '', fiscal_year_bn: fiscalYear !== undefined ? fiscalYear.text_bn : '' }
        const seasonObj = { season_name: season !== undefined ? season.text_en : '', season_name_bn: season !== undefined ? season.text_bn : '' }
        const subsidyTypeObj = { subsidy_type_name: subsidyType !== undefined ? subsidyType.text_en : '', subsidy_type_name_bn: subsidyType !== undefined ? subsidyType.text_bn : '' }
        const subsidyObj = { subsidy_name: subsidy !== undefined ? subsidy.text_en : '', subsidy_name_bn: subsidy !== undefined ? subsidy.text_bn : '' }
        const projectObj = { project_name: project !== undefined ? project.text_en : '', project_name_bn: project !== undefined ? project.text_bn : '' }
        const districtObj = { district_name: district !== undefined ? district.text_en : '', district_name_bn: district !== undefined ? district.text_bn : '' }
        const upazillaObj = { upazilla_name: upazilla !== undefined ? upazilla.text_en : '', upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : '' }
        return Object.assign({}, item, fiscalYearObj, seasonObj, subsidyTypeObj, subsidyObj, projectObj, districtObj, upazillaObj)
      })
      return listData
    },
    getSubsidyList (subsidyTypeId) {
      const getSubsidyList = this.$store.state.incentiveGrant.commonObj.subsidyList.filter(item => item.status === 1 && item.subsidy_type_id === subsidyTypeId)
      return getSubsidyList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === divisionId)
      return districtList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0 && item.district_id === districtId)
      return upazilaList.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    }
  }
}
</script>
